import React from 'react';

import styled from 'styled-components';
import cornerSvg from '../../assets/corner.svg';

import RSVPLandingPage from './RSVPLandingPage';

const StyledPage = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
  height: 100%;
  border: 10px solid #5e334c;
  box-sizing: border-box;
  background-color: #fcf7ef;
  padding: 40px 10px;
  position: relative;
  min-width: 304px;
  min-height: 100vh;
`;


const Corner = styled.div`
  position: absolute;
  width: 142px;
  height: 142px;

  &.top-left {
    top: 0;
    left: 0;
  }

  &.top-right {
    top: 0;
    right: 0;
    transform: rotate(90deg);
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
    transform: rotate(-90deg);
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
  }

  img {
    width: 100%;
    height: 100%;
  }
`;


const RSVPPage = () => {
    return (
        <StyledPage>
            <Corner className="top-left"><img src={cornerSvg} alt="Corner SVG" /></Corner>
            <Corner className="top-right"><img src={cornerSvg} alt="Corner SVG" /></Corner>
            <Corner className="bottom-left"><img src={cornerSvg} alt="Corner SVG" /></Corner>
            <Corner className="bottom-right"><img src={cornerSvg} alt="Corner SVG" /></Corner>

            <RSVPLandingPage />
        </StyledPage>
    );
};

export default RSVPPage;