import React, {useState} from 'react';
import { Box, Button, Stack, Typography } from '@mui/joy'
import { Sheet } from '../components/Sheet';
import RSVPStatusSelector from '../components/RSVPStatusSelector';
import usePostRsvp from '../../api/usePostRsvp';

const eventNames = {
    "ForumHomeEvent": { name: "Haldi & Mehndi", date: "2022-01-01" },
    "Sangeet": { name: "Sangeet" },
    "KunalHomeEvent": { name: "Pre-Wedding Ceremonies" },
    "Wedding": { name: "Wedding" },
    "Reception": { name: "Reception" }
}

const groupByEvent = (data) => {
    const events = ['ForumHomeEvent', 'Sangeet', 'KunalHomeEvent', 'Wedding', 'Reception'];
    const groupedData = {};

    events.forEach(event => {
        groupedData[event] = data
            .filter(invitee => invitee[event])
            .map(invitee => ({
                InviteeName: invitee.InviteeName,
                InviteeID: invitee.InviteeID,
                HasRSVP: invitee.HasRSVP
            }));
    });

    return groupedData;
};

const transformInviteeData = (data) => {
    return data.map(invitee => ({
      InviteeName: invitee.InviteeName,
      InviteeID: invitee.InviteeID,
      ForumHomeEvent: invitee.ForumHomeEvent === 1 ? null : false,
      Sangeet: invitee.Sangeet === 1 ? null : false,
      KunalHomeEvent: invitee.KunalHomeEvent === 1 ? null : false,
      Wedding: invitee.Wedding === 1 ? null : false,
      Reception: invitee.Reception === 1 ? null : false
    }));
  };

const RSVPInputPage = ({ data }) => {

    const { mutate, isLoading } = usePostRsvp();

    const [response, setResponse] = useState(transformInviteeData(data))

    const groupedData = groupByEvent(data);

    const handleChange = (inviteeId, event, value) => {
        setResponse(prevResponse => 
            prevResponse.map(invitee => 
                invitee.InviteeID === inviteeId 
                    ? { ...invitee, [event]: value }
                    : invitee
            )
        );
    };

    const handleSubmit = () => {
        const submitData = {
            RSVPCode: data[0].RSVPCode,
            rsvps: response
        };

        mutate(submitData, {
            onSuccess: () => {
                console.log('RSVP submitted successfully');
            },
            onError: (error) => {
                console.error('Error submitting RSVP:', error);
            }
        });
    };


    return (
        <Sheet>
            {Object.keys(groupedData).map((eventName, index) => (
                <Box key={index}
                    width={400}
                    p={2}
                >
                    <Box display="flex" alignItems="center" marginBottom="15px">
                        <Box flex="1" height="1px" bgcolor="#4e342e" />
                        <Typography level='h2' textAlign={'center'} marginX={"10px"}>{eventNames[eventName].name}</Typography>
                        <Box flex="1" height="1px" bgcolor="#4e342e" />
                    </Box>
                    <Stack direction="column" spacing={2}>
                        {groupedData[eventName].map((invitee, idx) => (
                            <RSVPStatusSelector key={idx} invitee={invitee} idx={idx} lastId={groupedData[eventName].length} response={response} handleChange={handleChange} eventName={eventName}/>
                        ))}
                    </Stack>
                </Box>
            ))
            }
            <Button onClick={handleSubmit} isLoading={isLoading}>Submit</Button>
        </Sheet >

    );
};

export default RSVPInputPage;

