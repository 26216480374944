import React from 'react';
import { Stack, Radio, RadioGroup, Divider, Typography} from '@mui/joy'

const RSVPStatusSelector = ({ invitee, idx, lastId, response, handleChange, eventName }) => {
    
    const currentResponse = response.find(r => r.InviteeID === invitee.InviteeID);
   
    return (
        <>
            <Stack key={idx} direction="row" justifyContent={"space-between"} spacing={1} alignItems={"center"}>
                <Typography level='body-p'>{invitee.InviteeName}</Typography>
                <RadioGroup 
                    name={`radio-${invitee.InviteeID}-${eventName}`} 
                    orientation="vertical" 
                    value={currentResponse[eventName]}
                    onChange={(e) => handleChange(invitee.InviteeID, eventName, e.target.value === 'true')}
                >
                    <Radio value={true} label="Joyfully Attending" variant="outlined" />
                    <Radio value={false} label="Regretfully Decline" variant="outlined" />
                </RadioGroup>
            </Stack>
            {idx !== lastId - 1 && <Divider />}
        </>
    );
};

export default RSVPStatusSelector;