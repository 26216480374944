import React, {  useState } from 'react';
import { Input, Stack, Button, Typography } from '@mui/joy';
import { Sheet } from '../components/Sheet';
import useRsvpInfo from '../../api/useRsvpInfo';
import styled from 'styled-components';
import rsvpText from '../../assets/rsvpText.svg';
import RSVPInputPage from './RSVPInputPage';

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
//   margin-bottom: 20px;
`;


const RSVPLandingPage = () => {
    const [code, setCode] = useState('FCJO0A');
    const [submittedCode, setSubmittedCode] = useState(null);

    const { data, error, isLoading } = useRsvpInfo(submittedCode);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmittedCode(code);
    };

    return (
        <Sheet>
            {!data &&
                <>
                    <CenteredWrapper>
                        <img src={rsvpText} alt="RsvpText" />
                    </CenteredWrapper>
                    <Typography level="h4">Enter your unique R.S.V.P. Code</Typography>
                    <form onSubmit={handleSubmit}>
                        <Stack direction="row" spacing={2}>
                            <Input
                                placeholder="RSVP Code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <Button type="submit" loading={isLoading}>
                                Submit
                            </Button>
                        </Stack>
                    </form>
                </>
            }
            {error && <p>Error: {error.message}</p>}
            {data && (
                <RSVPInputPage data={data} />
            )}
        </Sheet>
    );
};

export default RSVPLandingPage;