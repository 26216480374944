import { useQuery } from '@tanstack/react-query';
const useRsvpInfo = (code) => {
    const fetchRsvpInfo = async () => {
        const response = await fetch(`/api/rsvp/${code}`);
        if (!response.ok) {
            throw new Error('Failed to fetch RSVP info');
        }
        const data = await response.json();
        return data
    };

    return useQuery({
        queryKey: ['rsvpInfo', code],
        queryFn: fetchRsvpInfo,
        enabled: !!code,
        select: data => data?.data?.results,
    });
};

export default useRsvpInfo;